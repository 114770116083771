import { Page as tobiiTopographic } from './pages/tobiiTopographic';
import { Page as tobiiPeople } from './pages/tobiiPeople';
import { Page as tobiiASL } from './pages/tobiiASL';
import { Page as tobiiTech } from './pages/tobiiTech';
import { Page as tobiiAnimals } from './pages/tobiiAnimals';
import { Page as tobiiObjects } from './pages/tobiiObjects';
import { Page as tobiiHospital } from './pages/tobiiHospital';
import { Page as tobiiAvatars } from './pages/tobiiAvatars';
import { Page as expiiIcons } from './pages/expiiIcons';
import { Page as thesis } from './pages/thesis';
import { Page as verdeBaby } from './pages/verdeBaby';
import { Page as verdeElephant } from './pages/verdeElephant';
import { Page as verdeGun } from './pages/verdeGun';
import { Page as verdeInternal } from './pages/verdeInternal';
import { Page as verdeLips } from './pages/verdeLips';
import { Page as verdeOccupy } from './pages/verdeOccupy';
import { Page as verdeSam } from './pages/verdeSam';
import { Page as verdeSweetheart } from './pages/verdeSweetheart';
import { Page as expiiMedia1 } from './pages/expiiMedia1';
import { Page as expiiMedia2 } from './pages/expiiMedia2';
import { Page as lloyd } from './pages/lloyd'; 
import { Page as hotspots } from './pages/hotspots';
import { Page as seasonsOfColor } from './pages/seasonsOfColor';
import { Page as growATomato } from './pages/growATomato';
import { Page as frida } from './pages/frida';
import { Page as perfectDay } from './pages/perfectDay';
import { Page as whoCanDoIt } from './pages/whoCanDoIt';
import { Page as trump } from './pages/trump';
import { Page as myCondition } from './pages/myCondition';
import { Page as tobiiPathways } from './pages/tobiiPathways';
import { Page as kingdomOfPlenty } from './pages/kingdomOfPlenty';
import { Page as expiiTurkey } from './pages/expiiTurkey';
import { Page as expiiPumpkins } from './pages/expiiPumpkins';
import { Page as expiiDLS } from './pages/expiiDLS';
import { Page as expiiOrnaments } from './pages/expiiOrnaments';
import { Page as expiiRibbon } from './pages/expiiRibbon';
import { Page as expiiBlackFriday } from './pages/expiiBlackFriday';
import { Page as expiiHannukah } from './pages/expiiHannukah';
import { Page as expiiNYE } from './pages/expiiNYE';
import { Page as expiiSkulls } from './pages/expiiSkulls';
import { Page as jumoBanana } from './pages/jumoBanana';
import { Page as anatomicalHeartGIF } from './pages/anatomicalHeartGIF';
import { Page as dadMotorcycleGIF } from './pages/dadMotorcycleGIF';
import { Page as jumoHolidayVideo } from './pages/jumoHolidayVideo';
import { Page as dadeCard } from './pages/dadeCard';
import { Page as whiteboardAnimation } from './pages/whiteboardAnimation';
import { Page as careconnect } from './pages/careconnect';
import { Page as t1dGwen } from './pages/t1dGwen';
import { Page as expiiGraphics } from './pages/expiiGraphics';
import { Page as jumoAchieve } from './pages/jumoAchieve';
import { Page as jumoGaucherActivity } from './pages/jumoGaucherActivity';
import { Page as goingForGold } from './pages/goingForGold';
import { Page as colorHair } from './pages/colorHair';
import { Page as colorBird } from './pages/colorBird';
import { Page as colorGirls } from './pages/colorGirls';
import { Page as joker } from './pages/joker';
import { Page as jackson } from './pages/jackson';
import { Page as selfColoredPencil } from './pages/selfColoredPencil';
import { Page as stillLifePencil } from './pages/stillLifePencil';
import { Page as inkHair } from './pages/inkHair';
import { Page as shell } from './pages/shell';
import { Page as handRing } from './pages/handRing';
import { Page as charcoalBust } from './pages/charcoalBust';
import { Page as orangeCharcoal } from './pages/orangeCharcoal';
import { Page as finishedFigureDrawing } from './pages/finishedFigureDrawing';
import { Page as boobs } from './pages/boobs';
import { Page as botanical } from './pages/botanical';
import { Page as dissectingSelf } from './pages/dissectingSelf';
import { Page as lintBig } from './pages/lintBig';
import { Page as lintSmall } from './pages/lintSmall';
import { Page as paintFigure } from './pages/paintFigure';
import { Page as pig } from './pages/pig';
import { Page as plane } from './pages/plane';
import { Page as retina } from './pages/retina';
import { Page as selfAcrylic } from './pages/selfAcrylic';
import { Page as selfGouache } from './pages/selfGouache';
import { Page as selfWaterColor } from './pages/selfWaterColor';
import { Page as watertower } from './pages/watertower';
import { Page as wonkyBushes } from './pages/wonkyBushes';
import { Page as wonkyTongue } from './pages/wonkyTongue';
import { Page as darwin } from './pages/darwin';
import { Page as dreamBox } from './pages/dreamBox';
import { Page as hairGod } from './pages/hairGod';
import { Page as lifePool } from './pages/lifePool';
import { Page as migraine } from './pages/migraine';
import { Page as petri } from './pages/petri';
import { Page as phenotype } from './pages/phenotype';
import { Page as SEM } from './pages/SEM';
import { Page as spider } from './pages/spider';
import { Page as tattoo } from './pages/tattoo';
import { Page as theKeyToUs } from './pages/theKeyToUs';
import { Page as codeRaiseToWake } from './pages/codeRaiseToWake';
import { Page as codeRandomizedFaces } from './pages/codeRandomizedFaces';
import { Page as codeCity } from './pages/codeCity';
import { Page as flipfoot } from './pages/flipfoot';
import { Page as expiiFlyer } from './pages/expiiFlyer';
import { Page as expiiSpark } from './pages/expiiSpark';
import { Page as tobiiShirt } from './pages/tobiiShirt';
import { Page as verdeShirt } from './pages/verdeShirt';
import { Page as starMaps } from './pages/starMaps';
import { Page as saveTheDate } from './pages/saveTheDate';
import { Page as WES } from './pages/WES';
import { Page as snellen } from './pages/snellen';
import { Page as resin } from './pages/resin';
import { Page as nailArt } from './pages/nailArt';
import { Page as paperFlowers } from './pages/paperFlowers';
import { Page as urn } from './pages/urn';
import { Page as bathSalts } from './pages/bathSalts';
import { Page as dadCo } from './pages/dadCo';
import { Page as soaps } from './pages/soaps';
import { Page as greetingCards } from './pages/greetingCards';
import { Page as CIAJ } from './pages/CIAJ';
import { Page as infographics } from './pages/infographics';
import { Page as postcards } from './pages/postcards';
import { Page as calendar } from './pages/calendar';
import { Page as blackboardAframe } from './pages/blackboardAframe';
import { Page as blackboardBanner } from './pages/blackboardBanner';
import { Page as flikidsFont } from './pages/flikidsFont';
import { Page as alienCallig } from './pages/alienCallig';
import { Page as latinCallig } from './pages/latinCallig';
import { Page as jabberwocky } from './pages/jabberwocky';
import { Page as momPillow } from './pages/momPillow';
import { Page as saraBook } from './pages/saraBook';
import { Page as punkNecklace } from './pages/punkNecklace';
import { Page as alyssa } from './pages/alyssa';
import { Page as figureSketch } from './pages/figureSketch';
import { Page as planetTactilus } from './pages/planetTactilus';
import { Page as iconAnimations } from './pages/iconAnimations';
import { Page as textAnimation } from './pages/textAnimation';
import { Page as transitionAnimations } from './pages/transitionAnimations';
import { Page as frameByFrame } from './pages/frameByFrame';
import { Page as codeBodyCards } from './pages/codeBodyCards';
import { Page as codeColorSliders } from './pages/codeColorSliders';
import { Page as codeDragMouseRainbowBalls } from './pages/codeDragMouseRainbowBalls';
import { Page as codeFlagMountains } from './pages/codeFlagMountains';
import { Page as codeFourPieChart } from './pages/codeFourPieChart';
import { Page as codeMarioGame } from './pages/codeMarioGame';
import { Page as codePaddleBall } from './pages/codePaddleBall';
import { Page as codePinkClock } from './pages/codePinkClock';
import { Page as codeSaraPortrait } from './pages/codeSaraPortrait';
import { Page as codeSoundBall } from './pages/codeSoundBall';
import { Page as codeTurtlesRacing } from './pages/codeTurtlesRacing';
import { Page as codeWalkingMan } from './pages/codeWalkingMan';
import { Page as codeYinAndYang } from './pages/codeYinAndYang';
import { Page as codePeaches } from './pages/codePeaches';
import { Page as tobiiAnimationAssets } from './pages/tobiiAnimationAssets';
import { Page as inktoberMoon } from './pages/inktoberMoon';
import { Page as inktoberApples } from './pages/inktoberApples';
import { Page as inktoberBouquet } from './pages/inktoberBouquet';
import { Page as inktoberFolliage } from './pages/inktoberFolliage';
import { Page as inktoberShroomHat } from './pages/inktoberShroomHat';
import { Page as inktoberPinecone } from './pages/inktoberPinecone';
import { Page as inktoberSandWitch } from './pages/inktoberSandWitch';
import { Page as inktoberCampfire } from './pages/inktoberCampfire';
import { Page as inktoberOwl } from './pages/inktoberOwl';
import { Page as inktoberSpider } from './pages/inktoberSpider';
import { Page as inktoberCider } from './pages/inktoberCider';
import { Page as inktoberShroomPizza } from './pages/inktoberShroomPizza';
import { Page as inktoberAcorns } from './pages/inktoberAcorns';
import { Page as inktoberPaperWitch } from './pages/inktoberPaperWitch';
import { Page as inktoberScarf } from './pages/inktoberScarf';
import { Page as inktoberCat } from './pages/inktoberCat';
import { Page as inktoberSweater } from './pages/inktoberSweater';
import { Page as inktoberBat } from './pages/inktoberBat';
import { Page as inktoberCandyCorn } from './pages/inktoberCandyCorn';
import { Page as inktoberScarecrow } from './pages/inktoberScarecrow';
import { Page as inktoberHauntedHouse } from './pages/inktoberHauntedHouse';
import { Page as inktoberTrickOrTreat } from './pages/inktoberTrickOrTreat';
import { Page as Hbook } from './pages/Hbook';
import { Page as HstarGift } from './pages/HstarGift';
import { Page as grataCanisMajor } from './pages/grataCanisMajor';
import { Page as expii_groundhog } from './pages/expii_groundhog';
import { Page as expii_waffle } from './pages/expii_waffle';
import { Page as expii_winter_olympics } from './pages/expii_winter_olympics';
import { Page as expii_oscars } from './pages/expii_oscars';
import { Page as expii_chinese_new_year } from './pages/expii_chinese_new_year';
import { Page as expii_presidents_day } from './pages/expii_presidents_day';
import { Page as expii_KID_day } from './pages/expii_KID_day';
import { Page as expii_watermelon_day } from './pages/expii_watermelon_day';
import { Page as expii_total_eclipse } from './pages/expii_total_eclipse';
import { Page as expii_pluto_demoted_day } from './pages/expii_pluto_demoted_day';
import { Page as expii_cat_day } from './pages/expii_cat_day';
import { Page as expii_tau_day } from './pages/expii_tau_day';
import { Page as expii_chess_day } from './pages/expii_chess_day';
import { Page as expii_pi_approximation_day } from './pages/expii_pi_approximation_day';




// PAGE FOR PROJECT INFORMATION (used by menu, gallery pages, etc)
// 
// format:
// 
// 	<ID>: {
// 		title: `<TITLE>`, 
// 		description: `<DESCRIPTION>`,
// 		pic: `<THUMBNAIL FILENAME>`,
// 		page: ??
// 

export const projects = {

//children's illo//

	'hotspots': {
		title: `Hotspot Pop-ups`,
		description: `Hover activated pop-up illustrations to pair with medical text online`,
		pic: `hotspots.jpg`,
		page: hotspots
	},


	'thesis': {
		title: `Undergrad Thesis: Communication Symbols`,
		description: `Researched most effective visuals to aid Shabbat participation for autistic and young audiences`,
		pic: `thesis_v2.jpg`,
		page: thesis
	},

	'frida': {
		title: `"Who Is Frida Kahlo?"`,
		description: `Educational book about Kahlo's life and legacy`,
		pic: `frida.jpg`,
		page: frida
	},

	'myCondition': {
		title: `"My Condition & Me"`,
		description: `Educational book for toddlers about their health condition`,
		pic: `gaucher2.jpg`,
		page: myCondition
	},

	'color': {
		title: `"A Perfect Day"`,	
		description: `Max and Zoey's perfect day`,	
		pic: `color.jpg`,
		page: perfectDay
	},

	'achieve': {
		title: `Activity Book`,
		description: `Book of coloring, stickers, and games`,
		pic: `achieve_v3.jpg`,
		page: jumoAchieve
	},

	'bunny': {
		title: `"Growing a Tomato"`,
		description: `Educational book about gardening`,
		pic: `bunny.jpg`,
		page: growATomato
	},

	'activity': {
		title: `Activity Book`,
		description: `Book of coloring and games for children`,
		pic: `activity_v2.jpg`,
		page: jumoGaucherActivity
	},

	'trump': {
		title: `"The White House"`,
		description: `Educational book about the White House and inauguration`,
		pic: `trump.jpg`,
		page: trump
	},

	'energy': {
		title: `"Who Can Do It?"`,
		description: `Educational book about sustainability efforts`,
		pic: `energy.jpg`,
		page: whoCanDoIt
	},

	'pathways': {
		title: `"Pathways"`,	
		description: `Guide for kids and teachers on using a new product`,	
		pic: `pathways.jpg`,
		page: tobiiPathways
	},

	'fall': {
		title: `"Seasons of Color"`,
		description: `Educational book about colors associated with seasons`,
		pic: `fall.jpg`,
		page: seasonsOfColor
	},

	't1d': {
		title: `"T1D & Gwen"`,
		description: `Book concept for teens with Type 1 Diabetes`,
		pic: `t1d3.jpg`,
		page: t1dGwen
	},



	'expii': {
		title: `Expii Graphics`,
				description: `Graphics to pair with Expii's e-learning webiste`,
		pic: `expii.jpg`,
		page: expiiGraphics
	},

	'expii_groundhog': {
		title: `Math Problem: "Groundhog Day"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/12.png`,
		page: expii_groundhog
	},

	'expii_waffle': {
		title: `Math Problem: "Waffle Day"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/21.png`,
		page: expii_waffle
	},

	'expii_winter_olympics': {
		title: `Math Problem: "Winter Olympics"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/19.png`,
		page: expii_winter_olympics
	},

	'expii_oscars': {
		title: `Math Problem: "Oscars"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/16.png`,
		page: expii_oscars
	},

	'expii_chinese_new_year': {
		title: `Math Problem: "Chinese New Year"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/13.png`,
		page: expii_chinese_new_year
	},

	'expii_presidents_day': {
		title: `Math Problem: "Presidents Day"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/17.png`,
		page: expii_presidents_day
	},

	'expii_KID_day': {
		title: `Math Problem: "Kid Inventor Day, K.I.D."`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/14.png`,
		page: expii_KID_day
	},

	'expii_watermelon_day': {
		title: `Social Media: "Watermelon Day"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/8.png`,
		page: expii_watermelon_day
	},

	'expii_total_eclipse': {
		title: `Social Media: "Total Eclipse"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/11.png`,
		page: expii_total_eclipse
	},

	'expii_pluto_demoted_day': {
		title: `Social Media: "Pluto Demoted Day"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/10.png`,
		page: expii_pluto_demoted_day
	},

	'expii_cat_day': {
		title: `Social Media: "Cat Day"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/9.png`,
		page: expii_cat_day
	},

	'expii_tau_day': {
		title: `Social Media: "Tau Day"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/2.png`,
		page: expii_tau_day
	},

	'expii_chess_day': {
		title: `Social Media: "Chess Day"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/6.png`,
		page: expii_chess_day
	},

	'expii_pi_approximation_day': {
		title: `Social Media: "Pi Approximation Day"`,
				description: `Adobe Illustrator`,
		pic: `expiiGraphics_copy/7.png`,
		page: expii_pi_approximation_day
	},



	'andreaBook': {
		title: `"Kingdom of Plenty"`,
		description: `A children's book concept`,
		pic: `andreaBook.jpg`,
		page: kingdomOfPlenty
	},

	'goingForGold': {
		title: `"Going for Gold"`,
		description: `Story book about running and competition`,
		pic: `goingForGold.jpg`,
		page: goingForGold
	},

	'Hbook': {
		title: `"The 25 Book"`,
		description: `A children's book style biography of my 25-year-old partner`,
		pic: `Hbook3.jpg`,
		page: Hbook
	},


//media illo//

	'grataCanisMajor': {
		title: `"Canis Major" Release`,
		description: `Product line release, themed around the Canis Major constellation`,
		pic: `grataCanisMajor.jpg`,
		page: grataCanisMajor
	},

	'lloyd': {
		title: `"The Eleventh Hour"`,
		description: `Prog rock album cover`,
		pic: `lloyd.jpg`,
		page: lloyd
	},

	'expiiMedia1': {
		title: `Ed-Tech Illustration`,
		description: `Depicting varying math and science explanation types (text, video, drawn) housed on an e-learning site`,
		pic: `expii1.jpg`,
		page: expiiMedia1
	},

	'expiiMedia2': {
		title: `Ed-Tech Illustration`,
		description: `Depicting a sense of community and communication on social media for an e-learning site`,
		pic: `expii2_v3.jpg`,
		page: expiiMedia2
	},

	'verdeBaby': {
		title: `"Halloween: All Grown Up?"`,
		description: `News magazine cover illustration`,
		pic: `verdeBaby.jpg`,
		page: verdeBaby
	},

	'verdeSam': {
		title: `"I Want YOU to be Happy"`,
		description: `News magazine cover illustration`,
		pic: `verdeSam.jpg`,
		page: verdeSam
	},

	'verdeElephant': {
		title: `"The Elephant in the Classroom"`,	
		description: `News magazine cover illustration`,	
		pic: `verdeElephant.jpg`,
		page: verdeElephant
	},

	'verdeSweetheart': {
		title: `"Don't Call Me Sweetheart"`,
		description: `News magazine cover illustration`,
		pic: `verdeSweetheart.jpg`,
		page: verdeSweetheart
	},

	'verdeGun': {
		title: `"Gunning for Change"`,
		description: `News magazine cover illustration`,
		pic: `verdeGun.jpg`,
		page: verdeGun
	},

	'verdeLips': {
		title: `Untitled`,
		description: `News magazine cover illustration`,
		pic: `verdeLips.jpg`,
		page: verdeLips
	},

	'verdeOccupy': {
		title: `"Occupy [Here]"`,
		description: `News magazine cover illustration`,
		pic: `verdeOccupy.jpg`,
		page: verdeOccupy
	},

	'verdeInternal': {
		title: `News Article Illustrations`,
		description: `Illustrations to pair with news articles`,
		pic: `verdeInternal.jpg`,
		page: verdeInternal
	},

//iconography//

	'tobiiAnimals': {
		title: `Animals Symbols`,
		description: `Picture communication symbols (PCS) on general conversation topics`,
		pic: `thinlineThumbnail.jpg`,
		page: tobiiAnimals
	},

	'tobiiPeople': {
		title: `People Symbols`,
		description: `Picture communication symbols (PCS) on conversation topics about people`,
		pic: `peopleThumbnail.jpg`,
		page: tobiiPeople
	},

	'tobiiObjects': {
		title: `Object Symbols`,
		description: `Picture communication symbols (PCS) on conversation topics about objects`,
		pic: `objectsThumbnail.jpg`,
		page: tobiiObjects
	},

	'tobiiTech': {
		title: `Technology Symbols`,
		description: `Picture communication symbols (PCS) on conversation topics about technology`,
		pic: `techThumbnail.jpg`,
		page: tobiiTech
	},

	'tobiiTopographic': {
		title: `Topographic Symbols`,
		description: `Picture communication symbols (PCS) to educate nonverbal children about topography`,
		pic: `africaThumbnail.jpg`,
		page: tobiiTopographic
	},

	'tobiiASL': {
		title: `American Sign Language`,
		description: `Picture communication symbols (PCS) on American Sign Language vocabulary`,
		pic: `aslThumbnail2.jpg`,
		page: tobiiASL
	},

	'tobiiHospital': {
		title: `Hospital Symbols`,
		description: `Picture communication symbols (PCS) to aid communication in a hospital setting`,
		pic: `hospitalThumbnail2.jpg`,
		page: tobiiHospital
	},

	'tobiiAvatars': {
		title: `Avatars`,
		description: `Visual representation of students' teachers`,
		pic: `avatarVictoria_s.jpg`,
		page: tobiiAvatars
	},

	'expiiIcons': {
		title: `"Get to Know Us": Hobby Icons`,
		description: `Icon representation of a company's individual team members' interests, for social media`,
		pic: `expiiIconFeature2.jpg`,
		page: expiiIcons
	},
	


//videos//

	'dadCard': {
		title: `Father's Day e-Card`,
		description: 'Adobe After Effects',
		pic: `dadcard2.gif`,
		page: dadeCard
	},

	'whiteboard': {
		title: `Whiteboard Animation`,
		description: 'Adobe After Effects',
		pic: `whiteboard2.gif`,
		page: whiteboardAnimation
	},

	'holiday': {
		title: `Holiday e-Card`,
		description: 'Adobe After Effects',
		pic: `holiday2.gif`,
		page: jumoHolidayVideo
	},

	'characterExplainer': {
		title: `Character Explainer Videos`,
		description: 'Adobe After Effects',
		pic: `characterExplainer44.gif`,
		page: careconnect
	},

	'iconExplainer': {
		title: `Icon Explainer Videos`,
		description: 'Adobe After Effects',
		pic: `iconExplainer2.gif`,
		page: iconAnimations
	},

	'textAnimation': {
		title: `Text Animation`,
		description: 'Adobe After Effects',
		pic: `textAnimation2.gif`,
		page: textAnimation
	},

	'frameByFrame': {
		title: `Frame by Frame Animation`,
		description: 'Adobe Photoshop',
		pic: `frameByFrame2.gif`,
		page: frameByFrame
	},

	'transitionScreens': {
		title: `Transition Screens`,
		description: 'Adobe After Effects',
		pic: `transitions2.gif`,
		page: transitionAnimations
	},

	'assetCreation': {
		title: `Asset Creation`,
		description: 'Adobe Illustrator',
		pic: `lizAnimation3.gif`,
		page: tobiiAnimationAssets
	},

//loops//

	'inktoberMoon': {
		title: `Giftober: "Harvest Moon"`,
		description: 'Adobe After Effects',
		pic: `inktoberMoon.gif`,
		page: inktoberMoon
	},
	'inktoberApples': {
		title: `Giftober: "Apple Picking"`,
		description: 'Adobe After Effects',
		pic: `inktoberApples.gif`,
		page: inktoberApples
	},
	'inktoberBouquet': {
		title: `Giftober: "Harvest Bouquet"`,
		description: 'Adobe After Effects',
		pic: `inktoberBouquet.gif`,
		page: inktoberBouquet
	},
	'inktoberFolliage': {
		title: `Giftober: "Fall Folliage"`,
		description: 'Adobe After Effects',
		pic: `inktoberFolliage.gif`,
		page: inktoberFolliage
	},
	'inktoberShroomHat': {
		title: `Giftober: "Mushroom"`,
		description: 'Adobe After Effects',
		pic: `inktoberShroomHat.gif`,
		page: inktoberShroomHat
	},
	'inktoberPinecone': {
		title: `Giftober: "Skydiving Pinecone"`,
		description: 'Adobe After Effects',
		pic: `inktoberPinecone.gif`,
		page: inktoberPinecone
	},
	'inktoberSandWitch': {
		title: `Giftober: "Sand-witch"`,
		description: 'Adobe After Effects',
		pic: `inktoberSandWitch.gif`,
		page: inktoberSandWitch
	},
	'inktoberCampfire': {
		title: `Giftober: "Campfire"`,
		description: 'Adobe After Effects',
		pic: `inktoberCampfire.gif`,
		page: inktoberCampfire
	},
	'inktoberOwl': {
		title: `Giftober: "Drowsy Owl"`,
		description: 'Adobe After Effects',
		pic: `inktoberOwl.gif`,
		page: inktoberOwl
	},
	'inktoberSpider': {
		title: `Giftober: "Shy Spider"`,
		description: 'Adobe After Effects',
		pic: `inktoberSpider.gif`,
		page: inktoberSpider
	},
	'inktoberCider': {
		title: `Giftober: "Hot Cider"`,
		description: 'Adobe After Effects',
		pic: `inktoberCider.gif`,
		page: inktoberCider
	},
	'inktoberShroomPizza': {
		title: `Giftober: "Mushroom Pizza"`,
		description: 'Adobe After Effects',
		pic: `inktoberShroomPizza.gif`,
		page: inktoberShroomPizza
	},
	'inktoberAcorns': {
		title: `Giftober: "Dancing Acorns"`,
		description: 'Adobe After Effects',
		pic: `inktoberAcorns.gif`,
		page: inktoberAcorns
	},
	'inktoberPaperWitch': {
		title: `Giftober: "Floating Witch"`,
		description: 'Adobe After Effects',
		pic: `inktoberPaperWitch.gif`,
		page: inktoberPaperWitch
	},
	'inktoberScarf': {
		title: `Giftober: "Dog Scarf"`,
		description: 'Adobe After Effects',
		pic: `inktoberScarf.gif`,
		page: inktoberScarf
	},
	'inktoberCat': {
		title: `Giftober: "Sneaking Cat"`,
		description: 'Adobe After Effects',
		pic: `inktoberCat.gif`,
		page: inktoberCat
	},
	'inktoberSweater': {
		title: `Giftober: "Sweater Weather"`,
		description: 'Adobe After Effects',
		pic: `inktoberSweater.gif`,
		page: inktoberSweater
	},
	'inktoberBat': {
		title: `Giftober: "Sleeping Bat"`,
		description: 'Adobe After Effects',
		pic: `inktoberBat.gif`,
		page: inktoberBat
	},
	'inktoberCandyCorn': {
		title: `Giftober: "Dancing Candy Corn"`,
		description: 'Adobe After Effects',
		pic: `inktoberCandyCorn.gif`,
		page: inktoberCandyCorn
	},
	'inktoberScarecrow': {
		title: `Giftober: "Scarecrow"`,
		description: 'Adobe After Effects',
		pic: `inktoberScarecrow.gif`,
		page: inktoberScarecrow
	},
	'inktoberHauntedHouse': {
		title: `Giftober: "Haunted Dog House"`,
		description: 'Adobe After Effects',
		pic: `inktoberHauntedHouse.gif`,
		page: inktoberHauntedHouse
	},
	'inktoberTrickOrTreat': {
		title: `Giftober: "Trick or Treat"`,
		description: 'Adobe After Effects',
		pic: `inktoberTrickOrTreat.gif`,
		page: inktoberTrickOrTreat
	},

	'dadMotorcycle': {
		title: `"Dad's Motorcycle"`,
		description: 'Adobe After Effects',
		pic: `dadMotorcyclePurple.gif`,
		page: dadMotorcycleGIF
	},

	'banana': {
		title: `"Healthy Food"`,
		description: 'Adobe After Effects',
		pic: `banana.gif`,
		page: jumoBanana
	},

	'heart': {
		title: `Anatomical Valentine`,
		description: 'Adobe After Effects',
		pic: `heart.gif`,
		page: anatomicalHeartGIF
	},

	'skulls': {
		title: `Math Problem: "I Have a Bone to Pick"`,
		description: 'Adobe After Effects',
		pic: `skulls-small.gif`,
		page: expiiSkulls
	},

	'turkey': {
		title: `Math Problem: "Parading Around"`,
		description: 'Adobe After Effects',
		pic: `turkey-small.gif`,
		page: expiiTurkey
	},

	'pumpkins': {
		title: `Math Problem: "Pumpkin Overload"`,
		description: 'Adobe After Effects',
		pic: `pumpkins-small.gif`,
		page: expiiPumpkins,
		preferred_menu_id: 'expii'
	},

	'clock': {
		title: `Math Problem: "Save the Day(light)"`,
		description: 'Adobe After Effects',
		pic: `clock-small.gif`,
		page: expiiDLS
	},

	'hanukkah': {
		title: `Math Problem: "Hanukkah Candles"`,
		description: 'Adobe After Effects',
		pic: `hanukkah-small.gif`,
		page: expiiHannukah
	},

	'bFriday': {
		title: `Math Problem: "Discount Dash"`,
		description: 'Adobe After Effects',
		pic: `bFriday-small.gif`,
		page: expiiBlackFriday
	},

	'ornaments': {
		title: `Math Problem: "Weigh the Ornaments"`,
		description: 'Adobe After Effects',
		pic: `ornaments-small.gif`,
		page: expiiOrnaments
	},

	'ribbon': {
		title: `Math Problem: "Cut the Ribbon"`,
		description: 'Adobe After Effects',
		pic: `ribbon-small.gif`,
		page: expiiRibbon
	},

	'NYE': {
		title: `Math Problem: "Drop the Ball"`,
		description: 'Adobe After Effects',
		pic: `NYE-small.gif`,
		page: expiiNYE
	},

//drawing//

	'inkHair': {
		description: `Ink`,
		pic: `inkHair.jpg`,
		page: inkHair
	},

	'joker': {
		description: `Graphite`,
		pic: `joker.jpg`,
		page: joker
	},

	'alyssa': {
		description: `Graphite`,
		pic: `alyssa.jpg`,
		page: alyssa
	},

	'jackson': {
		description: `Graphite`,
		pic: `jackson.jpg`,
		page: jackson
	},

	'selfPencil': {
		description: `Colored pencil`,
		pic: `selfPencil.jpg`,
		page: selfColoredPencil
	},

	'stillLifePencil': {
		description: `Graphite`,
		pic: `stillLifePencil.jpg`,
		page: stillLifePencil
	},

	'icyGirl': {
		description: `Digital Color Study: Bridging`,
		pic: `icyGirl.jpg`,
		page: colorGirls
	},

	'charcoal': {
		description: `Charcoal`,
		pic: `charcoal.jpg`,
		page: charcoalBust
	},

	'orangeDrawing': {
		description: `Acrylic, charcoal`,
		pic: `orangeDrawing.jpg`,
		page: orangeCharcoal
	},

	'colorShadowGirl': {
		description: `Digital Color Study: Warm vs. Cool`,
		pic: `colorShadowGirl.jpg`,
		page: colorHair
	},

	'figure1': {
		description: `Charcoal`,
		pic: `figure1.jpg`,
		page: 0
	},

	'figure2': {
		description: `Charcoal`,
		pic: `figure2.jpg`,
		page: 0
	},

	'figure3': {
		description: `Charcoal`,
		pic: `figure3.jpg`,
		page: figureSketch
	},

	'figure4': {
		description: `Charcoal`,
		pic: `figure4.jpg`,
		page: 0
	},

	'figure5': {
		description: `Charcoal`,
		pic: `figure5_v3.jpg`,
		page: finishedFigureDrawing
	},

	'shell': {
		description: `Graphite`,
		pic: `shell.jpg`,
		page: shell
	},

	'handCharcoal': {
		description: `Charcoal`,
		pic: `handCharcoal.jpg`,
		page: handRing
	},

	'birb': {
		description: `Digital Color Study: Triad`,
		pic: `birb.jpg`,
		page: colorBird
	},


//painting://
	
	'boobs': {
		title: `"Uncanny"`,
		description: `Intersection of breasts and seashells, oil`,
		pic: `boobs_s.jpg`,
		page: boobs
	},

	'dissect': {
		title: `"Dissecting Myself"`,
		description: `Insect pinning a self portrait, acrylic`,
		pic: `dissect_s.jpg`,
		page: dissectingSelf
	},

	'eye': {
		title: `"Retinal Embossing"`,
		description: `Gold-embossed portrait of a retina, oil & acrylic`,
		pic: `eye_s.jpg`,
		page: retina
	},

	'huesGrid': {
		title: `Hues Grid`,
		description: `A painting comprised of 1 luminance level, oil`,
		pic: `huesGrid_s.jpg`,
		page: 0
	},

	'wonky1': {
		title: `Untitled`,
		description: `Mixed media`,
		pic: `wonky1.jpg`,
		page: wonkyTongue
	},

	'wonky2': {
		title: `"Look Me in the Eye"`,
		description: `Mixed media`,
		pic: `wonky2.jpg`,
		page: wonkyBushes
	},

	'jesusme': {
		title: `Untitled`,
		description: `Oil`,
		pic: `jesusme_s.jpg`,
		page: 0
	},

	'Lint': {
		title: `"Rococo Lint" #1`,
		description: `Dryer lint as paint`,
		pic: `Lint_s.jpg`,
		page: lintSmall
	},

	'botanical1': {
		title: `Botanical Self Portraits`,
		description: `Plants from the places I have lived,  watercolor`,
		pic: `sequoia.jpg`,
		page: botanical
	},

	'botanical2': {
		title: `Botanical Self Portrait - Sansevieria`,
		description: `Plants from the places I have lived,  watercolor & colored pencil`,
		pic: `snakePlant.jpg`,
		page: 0
	},

	'botanical3': {
		title: `Botanical Self Portrait - CA Poppy`,
		description: `Plants from the places I have lived,  watercolor & colored pencil`,
		pic: `poppy.jpg`,
		page: 0
	},

	'botanical4': {
		title: `Botanical Self Portrait - Ginkgo`,
		description: `Plants from the places I have lived,  watercolor & colored pencil`,
		pic: `ginkgo.jpg`,
		page: 0
	},

	'model': {
		title: `Untitled`,
		description: `Figure study, oil`,
		pic: `model_s.jpg`,
		page: paintFigure
	},

	'plane': {
		title: `Untitled`,
		description: `Inspired by the sky, oil`,
		pic: `plane_s.jpg`,
		page: plane
	},

	'rococoLint': {
		title: `"Rococo Lint" #2`,
		description: `Dryer lint as paint`,
		pic: `rococoLint_s.jpg`,
		page: lintBig
	},

	'young2': {
		title: `Untitled`,
		description: `Self portrait, acrylic`,
		pic: `young1_v2.jpg`,
		page: selfAcrylic
	},

	'young4': {
		title: `Untitled`,
		description: `Self portrait, watercolor`,
		pic: `young4_s.jpg`,
		page: selfWaterColor
	},

	'young5': {
		title: `Untitled`,
		description: `Self portrait, gouache`,
		pic: `young5_s.jpg`,
		page: selfGouache
	},

	'young7': {
		title: `Untitled`,
		description: `Acrylic`,
		pic: `young7_s.jpg`,
		page: watertower
	},

	'young8': {
		title: `"American Pig"`,
		description: `Acrylic`,
		pic: `young8_s.jpg`,
		page: pig
	},

//mixed media://

	'spider': {
		title: `Spider Brooch`,
		description: `A clay sculpture mixing beauty and disgust`,
		pic: `spider_s.png`,
		page: spider
	},

	'flipfoot': {
		title: `"Flipfoot" Flipbook`,
		description: `Flipbook illustrating the progression of Complex Regional Pain Syndrome in the body`,
		pic: `flipfoot.jpg`,
		page: flipfoot
	},

	'spaceArt': {
		title: `"Planet Tactilus" Interactive Installation`,
		description: `A simulation where tactile perception is experienced through auditory perception`,
		pic: `spaceArt.jpg`,
		page: planetTactilus
	},

	'artBioPetri': {
		title: `Petri Portraits`,
		description: `A family portrait with my family members drawn in each of their bacteria`,
		pic: `artBioPetri.jpg`,
		page: petri
	},

	'migraineGlasses': {
		title: `Migraine Goggles`,
		description: `Simulation of various migraine visual effects`,
		pic: `migraine.jpg`,
		page: migraine
	},

	'phenotype': {
		title: `"Phenotype vs. Genotype"`,
		description: `Illustrations and gene samples, contrasting people's similar phenotypes with their varying genotypes`,
		pic: `phenotype.jpg`,
		page: phenotype
	},

	'hairGod': {
		title: `"Hair God"`,
		description: `A god-like structure constructed of hair`,
		pic: `hairGod.jpg`,
		page: hairGod
	},

	'dreamBox': {
		title: `"Dream Box"`,
		description: `Installation on the elusiveness of dreams`,
		pic: `dreamBox.gif`,
		page: dreamBox
	},

	'ducks': {
		title: `"Life Pool"`,
		description: `A commentary on abuse of animals in research`,
		pic: `ducks.jpg`,
		page: lifePool
	},

	'tattoo': {
		title: `Anatomical Heart Tattoo`,
		description: `A connect-the-dots tattoo of an anatomical heart diagram, over the location of the real heart`,
		pic: `tattoo.jpg`,
		page: tattoo
	},

	'sugarBoxes': {
		title: `"The Key to Us"`,
		description: `An representation of the Lock and Key Theory in biology`,
		pic: `sugarBoxes.jpg`,
		page: theKeyToUs
	},

	'blueWave': {
		title: `"Invisible Beauty"`,
		description: `Photo manipulation of a Scanning Electron Microscope (SEM) capture of microscopic botany samples`,
		pic: `blueWave.jpg`,
		page: SEM
	},

	'darwinPortraits': {
		title: `Darwinian Portraits`,
		description: `A family portrait with my family as Darwin finches, exhibiting our varying "beak phenotypes"`,
		pic: `darwin_s copy.png`,
		page: darwin
	},

//code//

	'104bodyCards': {
		title: `"Mismatched"`,
		description: `Randomized permutations of characters' cards with mouse click`,
		pic: `104bodies.gif`,
		page: codeBodyCards
	},

	'104city': {
		title: `"City Scape"`,
		description: `A city scene with randomized clouds, cars, and planes`,
		pic: `codeCity.gif`,
		page: codeCity
	},

	'104colorSliders': {
		title: `RBG Sliders`,
		description: `Scale sliders for red, green, and blue color values with mouse drag`, 
		pic: `codeColorSliders.gif`,
		page: codeColorSliders
	},

	'104dragMouseRainbowBalls': {
		title: `Rainbow Bubbles`,
		description: `Randomized colors, implementation of gravity, and repopulation of bubbles with mouse drag`, 
		pic: `codeRainbowBalls.gif`,
		page: codeDragMouseRainbowBalls
	},

	'104eyes': {
		title: `"Raise to Wake"`,
		description: `Wake your monster by raising the sun and lowering the moon`,
		pic: `104eyes.gif`,
		page: codeRaiseToWake
	},

	'104faces': {
		title: `Randomized Faces`,
		description: `Randomized permutations of facial features with mouse click`,
		pic: `104faces_v2.gif`,
		page: codeRandomizedFaces
	},

	'104flagMountains': {
		title: `"King of the Hill"`,
		description: `Randomized hill heights are evaluated for highest points, then marked by a flag at those points`, 
		pic: `codeKingOfTheHill.gif`,
		page: codeFlagMountains
	},

	'104fourPieChart': {
		title: `Randomized Pie Chart`,
		description: `A pie chart that has randomized segments with randomized values`, 
		pic: `codePieChartRandomized.gif`,
		page: codeFourPieChart
	},

	'104marioGame': {
		title: `Mario Platformer Game`,
		description: `A recreation of the famous platformer game`, 
		pic: `codeMario.gif`,
		page: codeMarioGame
	},

	'104paddleBall': {
		title: `Pong Game`,
		description: `A game of pong with randomized vertical velocities`,
		pic: `104paddleBall.gif`,
		page: codePaddleBall
	},

	'104peaches': {
		title: `"Geometric Peaches"`,
		description: `Peaches that have a "cardioid" mathematical curve coded into their outlines, rotated with mouse location`, 
		pic: `codePeaches.gif`,
		page: codePeaches
	},

	'104pinkClock': {
		title: `Clock`,
		description: `Hour, minute, and second accurate clock graphic, using your computer's time zone`, 
		pic: `codeClock.gif`,
		page: codePinkClock
	},

	'104saraPortrait': {
		title: `Bubble Portrait`,
		description: `A custom paintbrush effect to reveal a portrait image`, 
		pic: `codeSara.gif`,
		page: codeSaraPortrait
	},

	'104soundBall': {
		title: `Ball Sound Effects`,
		description: `Varying sound effects generated based on where a bouncing ball hits`, 
		pic: `codeBallSounds.gif`,
		page: codeSoundBall
	},

	'104turtlesRacing': {
		title: `Turtle Race`,
		description: `"Turtle" elements race each other to finish line, with randomized speeds each time`, 
		pic: `codeTurtleRace.gif`,
		page: codeTurtlesRacing
	},

	'104walkingMan': {
		title: `"Follower"`,
		description: `A walk cycle character who follows where your mouse clicks`,
		pic: `104walkCycle.gif`,
		page: codeWalkingMan
	},

	'104yinAndYang': {
		title: `"Yin and Yang"`,
		description: `Move your mouse to have yin and yang oppose each other like magnets`,
		pic: `104oppositeBalls.gif`,
		page: codeYinAndYang
	},

//graphic design//

	'sparkBranding': {
		title: `Branding: "Project Spark"`,
		description: `Branding design for a company's product campaign`,
		pic: `sparkBranding.jpg`,
		page: expiiSpark
	},

	'expiiFlyer': {
		title: `Company Flyer: Expii`,
		description: `A promotional flyer to gain recognition for an online learning website`,
		pic: `expiiFlyer.jpg`,
		page: expiiFlyer
	},

	'saveTheDate': {
		title: `Save the Date Postcards`,
		description: `Postcard-style save the dates for a wedding`,
		pic: `saveTheDate.jpg`,
		page: saveTheDate
	},

	'postcards': {
		title: `Marketing Postcards`,
		description: `Postcards to engage existing users and gain new users of a platform`,
		pic: `postcards.jpg`,
		page: postcards
	},

	'snellen': {
		title: `Personalized Snellen Chart`,
		description: `Mother's Day gift of a personalized Snellen Chart for my ophthalmologist mom`,
		pic: `snellen.jpg`,
		page: snellen
	},

	'CIAJ': {
		title: `Event Flyers: Workshops`,
		description: `Flyers for a cancer-related workshop`,
		pic: `CIAJflyers.jpg`,
		page: CIAJ
	},

	'starMap': {
		title: `Star Maps`,
		description: `Prints including a map of the stars at certain meaningful dates and locations`,
		pic: `starMap.jpg`,
		page: starMaps
	},

	'tobiiShirt': {
		title: `Shirt Design: ALS Walk`,
		description: `A shirt design for Tobii Dynavox's participation in the local Pittsburgh ALS fundraiser event`,
		pic: `tobiiShirt.jpg`,
		page: tobiiShirt
	},

	'pirateShirt': {
		title: `Shirt Design: Alpha Phi x Princess Bride`,
		description: `Photo manipulation of a Scanning Electron Microscope (SEM) capture of a microscopic botany sample`,
		pic: `pirateShirt.jpg`,
		page: 0
	},

	'verdeShirt': {
		title: `Shirt Design: Verde Magazine`,
		description: `A shirt design for Verde Magazine's staff members`,
		pic: `verdeShirt.jpg`,
		page: verdeShirt
	},

	'infographics': {
		title: `Infographics`,
		description: `A collection of infographics on medical topics`,
		pic: `mps1_v5.jpg`,
		page: infographics
	},

	'indesign': {
		title: `InDesign e-Guides`,
		description: `Career pathway e-guides built out in InDesign`,
		pic: `wes-01.jpg`,
		page: WES
	},

//craft//

	'paperFlowers': {
		title: `Paper Flowers`,
		description: `Handmade large and small decorative paper flowers`,
		pic: `paperFlowers.jpg`,
		page: paperFlowers
	},

	'punkNecklace': {
		title: `Jewelry`,
		description: `Collection of handmade jewelry`,
		pic: `punkNecklace.jpg`,
		page: punkNecklace
	},

	'dadCo': {
		title: `"Dad Co" Shirt and Album`,
		description: `Handmade shirt decal and a spoof of Bad Company's album cover with my dad's face on it`,
		pic: `dadCo_v2.jpg`,
		page: dadCo
	},

	'soaps': {
		title: `Handmade Soaps`,
		description: `Varying scented handmade soaps`,
		pic: `soaps.jpg`,
		page: 0
	},

	'urn': {
		title: `Clay Pet Urn`,
		description: `Small clay urn created with love for my dog, Pal`,
		pic: `urn.jpg`,
		page: urn
	},

	'blueSoap': {
		title: `Handmade Soaps`,
		description: `Soap bars with varying looks, scents, and dermatologic properties`,
		pic: `blueSoap.jpg`,
		page: soaps
	},

	'saraBook': {
		title: `Photo Album`,
		description: `Handmade photo album for a bachelorette trip`,
		pic: `saraBook.jpg`,
		page: saraBook
	},

	'bathSalts': {
		title: `Floral Bath Salts`,
		description: `Homemade lavendar and rose bath salts`,
		pic: `bathSalts.jpg`,
		page: bathSalts
	},

	'cal': {
		title: `Dry Erase Calendar`,
		description: `Calendar made from color swatches and a frame`,
		pic: `cal.jpg`,
		page: calendar
	},

	'resinBookmarks': {
		title: `Resin Bookmarks`,
		description: `Dried flowers suspended in resin to make varying bookmarks`,
		pic: `resinBookmarks.jpg`,
		page: resin
	},

	'momPillow': {
		title: `Watercolor Pillow`,
		description: `A throw pillow with a watercolor effect painted`,
		pic: `momPillow.jpg`,
		page: momPillow
	},

	'greetingCards': {
		title: `Greeting Cards`,
		description: `Collection of drawn and paper-cutout cards`,
		pic: `greetingCards.jpg`,
		page: greetingCards
	},

	'nail-pier': {
		title: `Nail Art - Pier`,
		description: `Nail polish`,
		pic: `nail-pier.jpg`,
		page: 0
	},

	'nail-nyc': {
		title: `Nail Art`,
		description: `Collection of nail polish art done on myself and others`,
		pic: `nail-nyc.jpg`,
		page: nailArt
	},

	'nail-tiger': {
		title: `Nail Art - Tiger`,
		description: `Nail polish`,
		pic: `nail-tiger.jpg`,
		page: 0
	},

	'nail-xmas': {
		title: `Nail Art - Christmas`,
		description: `Nail polish`,
		pic: `nail-xmas.jpg`,
		page: 0
	},

	'nail-moon': {
		title: `Nail Art - Moon`,
		description: `Nail polish`,
		pic: `nail-moon.jpg`,
		page: 0
	},

	'nail-sunset': {
		title: `Nail Art - Sunset`,
		description: `Nail polish`,
		pic: `nail-sunset.jpg`,
		page: 0
	},

	'HstarGift': {
		title: `"365 Reasons I Love You"`,
		description: `Homemade gift for anniversary with my partner`,
		pic: `HstarGift.jpg`,
		page: HstarGift
	},

	

//lettering//

	'lettering-board1': {
		title: `Chalkboard A-Frame Signs`,
		description: `Chalk on blackboard`,
		pic: `lettering-board1_v2.jpg`,
		page: blackboardAframe
	},

	'lettering-board2': {
		title: `Paper Source Advertising`,
		description: `Chalk on blackboard`,
		pic: `lettering-board2.jpg`,
		page: 0
	},

	'lettering-board3': {
		title: `Paper Source Advertising`,
		description: `Chalk on blackboard`,
		pic: `lettering-board3.jpg`,
		page: 0
	},

	'lettering-board4': {
		title: `Paper Source Advertising`,
		description: `Chalk on blackboard`,
		pic: `lettering-board4.jpg`,
		page: 0
	},

	'lettering-board5': {
		title: `Paper Source Advertising`,
		description: `Chalk on blackboard`,
		pic: `lettering-board5.jpg`,
		page: 0
	},

	'lettering-board6': {
		title: `Paper Source Advertising`,
		description: `Chalk on blackboard`,
		pic: `lettering-board6.jpg`,
		page: 0
	},

	'lettering-sign1': {
		title: `Product Labeling`,
		description: `Chalk on blackboard`,
		pic: `productLabeling3.jpg`,
		page: 0
	},

	'lettering-sign2': {
		title: `Chalkboard Banners`,
		description: `Chalk on blackboard`,
		pic: `lettering-sign2.jpg`,
		page: blackboardBanner
	},

	'lettering-sign3': {
		title: `Paper Source Advertising`,
		description: `Chalk on blackboard`,
		pic: `lettering-sign3.jpg`,
		page: 0
	},

	'lettering-letter1': {
		title: `Paper Mail Handlettering`,
		description: `Ink on paper`,
		pic: `lettering-letter1.jpg`,
		page: 0
	},

	'jabberwocky': {
		title: `"The Jabberwocky" Calligraphy Book`,
		description: `Ink, using nibs: C2, B2, pointed #56`,
		pic: `jabberwocky.jpg`,
		page: jabberwocky
	},

	'lettering-cmu1': {
		title: `Alien Calligraphy`,
		description: `Ink, using B2 nib`,
		pic: `lettering-cmu1.jpg`,
		page: alienCallig
	},

	'lettering-cmu2': {
		title: `Latin Calligraphy`,
		description: `Ink, using C2 nib`,
		pic: `lettering-cmu2.jpg`,
		page: latinCallig
	},

	'lettering-cmu3': {
		title: `Negative Space Lettering`,
		description: `Construction paper cutouts`,
		pic: `lettering-cmu3.jpg`,
		page: 0
	},

	'kidFont': {
		title: `Children's Book Font`,
		description: `Digital`,
		pic: `flikidsFont.jpg`,
		page: flikidsFont
	},

	dox_match: {
		description: `The Most Important Day for Med Students Needs Rethinking`,
		pic: 'dox/match.webp',
		link: 'https://opmed.doximity.com/articles/the-most-important-day-for-med-students-needs-rethinking'
	},
	dox_codeSwitching: {
		description: `Have Surgeons (Accidentally) Become Primary Care Doctors?`,
		pic: 'dox/codeSwitching.gif',
		link: 'https://opmed.doximity.com/articles/have-surgeons-accidentally-become-primary-care-doctors'
	},

	dox_portalMagicWand: {
		description: `Why I Don’t Have a PCP As a Doctor`,
		pic: 'dox/portalMagicWand.webp',
		link: 'https://opmed.doximity.com/articles/why-i-don-t-have-a-pcp-as-a-doctor'
	},

	dox_flowchartChange: {
		description: `The Top 3 Things We Should Add to the Medical School Curriculum`,
		pic: 'dox/flowchartChange.webp',
		link: 'https://opmed.doximity.com/articles/the-top-3-things-we-should-add-to-the-medical-school-curriculum'
	},

	dox_shadowHands: {
		description: `Assault Should Not Be a Part of the Job`,
		pic: 'dox/shadowHands.webp',
		link: 'https://opmed.doximity.com/articles/assault-should-not-be-a-part-of-the-job'
	},

	dox_socialSafetyNet: {
		description: `I Wish I Could Have Prescribed My Patient a Friend`,
		pic: 'dox/socialSafetyNet.webp',
		link: 'https://opmed.doximity.com/articles/i-wish-i-could-have-prescribed-my-patient-a-friend'
	},

	dox_languageBarrier: {
		description: `Every Patient Has a Right to Understand Their Medical Care`,
		pic: 'dox/language.webp',
		link: 'https://opmed.doximity.com/articles/every-patient-has-a-right-to-understand-their-medical-care'
	},

	dox_sparkChain: {
		description: `Finding a Patient’s Spark — And My Own`,
		pic: 'dox/sparkChain.webp',
		link: 'https://opmed.doximity.com/articles/finding-a-patient-s-spark-and-my-own'
	},

	dox_bestHospitals: {
		description: `The Medical School Rankings Mess: A Lose-Lose for Students and Schools`,
		pic: 'dox/bestHospitals.webp',
		link: 'https://opmed.doximity.com/articles/the-medical-school-rankings-mess-a-lose-lose-for-students-and-schools'
	},

	dox_1stepForward: {
		description: `The Beauty and Horror of Progress`,
		pic: 'dox/1stepForward.webp',
		link: 'https://opmed.doximity.com/articles/the-beauty-and-horror-of-progress'
	},

	dox_AIinTheWay: {
		description: `Why AI Won’t Replace Physicians Any Time Soon (or, The Value of Being There in Person)`,
		pic: 'dox/AIinTheWay.webp',
		link: 'https://opmed.doximity.com/articles/why-ai-won-t-replace-physicians-any-time-soon-or-the-value-of-being-there-in-person'
	},

	dox_blackBelt_whiteCoat: {
		description: `Black Belt, White Coat`,
		pic: 'dox/blackBelt_whiteCoat.webp',
		link: 'https://opmed.doximity.com/articles/black-belt-white-coat'
	},

	dox_RN_vs_NP: {
		description: `Who Will Teach Me How to Be an NP?`,
		pic: 'dox/RN_vs_NP.webp',
		link: 'https://opmed.doximity.com/articles/who-will-teach-me-how-to-be-an-np'
	},

	dox_AI_handshake: {
		description: `Can AI Cure Physician Burnout?`,
		pic: 'dox/AI_handshake.webp',
		link: 'https://www.doximity.com/articles/5ed698be-3179-4221-ab6f-8519c74f0cf1'
	},

	dox_collage_compare_vials: {
		description: `Does Using Ketamine for Induction Have a Lower Mortality Rate Than Etomidate?`,
		pic: 'dox/collage_compare_vials.webp',
		link: 'https://pharmacyjoe.com/does-using-ketamine-for-induction-have-a-lower-mortality-rate-than-etomidate/'
	},

	dox_ozempic_collage: {
		description: `The Dangers of Compounded Obesity Medications`,
		pic: 'dox/ozempic_collage.webp',
		link: 'https://www.doximity.com/articles/df6857a8-679a-44ad-bbac-8c1432d94d09'
	},

	dox_hobbies: {
		description: `Physicians Need Joy, Too: On Hobbies and the Creative Life`,
		pic: 'dox/hobbies.webp',
		link: 'https://www.doximity.com/articles/857480b3-579b-4a3e-9a71-cbf9c3426f28'
	},

	dox_AIclearPath: {
		description: `My Inbox Is Clear and My Patients Are Happy: Using AI in Medicine`,
		pic: 'dox/AIclearPath.webp',
		link: 'https://opmed.doximity.com/articles/my-inbox-is-clear-and-my-patients-are-happy-using-ai-in-medicine'
	},
	
	dox_klax: {
		description: `Emergency Medicine Hacks from Klax`,
		pic: 'dox/klax.webp',
		link: 'https://www.doximity.com/articles/83551355-afb2-4c4b-9609-a8d92243f02d'
	},

	dox_MRI_glitch: {
		description: `The False Reassurance of a Full-Body MRI Scan`,
		pic: 'dox/MRI_glitch.jpeg',
		link: 'https://www.doximity.com/articles/9afcdc6e-2613-4572-9c1c-8642bb260e23'
	},

	dox_age_is_a_funny_thing: {
		description: `Age Is a Funny Thing`,
		pic: 'dox/age_is_a_funny_thing.webp',
		link: 'https://www.doximity.com/articles/f1b65301-5dbd-4bee-8601-04530a298591'
	},

	dox_golden_rule_book: {
		description: `Motorcycles, Ladders, and Fat: A Trauma Surgeon on How to Avoid Trauma`,
		pic: 'dox/golden_rule_book.webp',
		link: 'https://www.doximity.com/articles/fbdf8226-77ec-4880-b0f1-735408cb5584'
	},

	dox_union: {
		description: `The Struggles of Trying to Unionize as a Resident`,
		pic: 'dox/union.webp',
		link: 'https://www.doximity.com/articles/65debcd9-d6c1-45ba-8ea1-9a0f474fb0f2'
	},

	dox_phone_notifs: {
		description: `The Night on Call I Was Paged Once a Minute`,
		pic: 'dox/phone_notifs.webp',
		link: 'https://www.doximity.com/articles/ce04ee5a-3b6e-4fb2-acaf-05f18308b881'
	},

	dox_son_of_man_AI: {
		description: `The Last Bariumslinger`,
		pic: 'dox/son_of_man_AI.webp',
		link: 'https://www.doximity.com/articles/5400fb13-6888-435e-a8ba-e36a25ee5dfd'
	},


	dox_thinker: {
		description: `A Case for Moral Healing`,
		pic: 'dox/thinker.webp',
		link: 'https://opmed.doximity.com/articles/a-case-for-moral-healing'
	},

	dox_plane: {
		description: `What It's Like to Be a Locums Doctor`,
		pic: 'dox/plane1.gif',
		link: 'https://opmed.doximity.com/articles/what-it-s-like-to-be-a-locums-doctor'
	},

	dox_then_vs_now: {
		description: `Medicine is Not What It Used to Be`,
		pic: 'dox/then_vs_now.gif',
		link: 'https://opmed.doximity.com/articles/medicine-is-not-what-it-used-to-be'
	},

	dox_microscope: {
		description: `Next Steps: Reevaluating the Value of Step 3`,
		pic: 'dox/microscope.webp',
		link: 'https://opmed.doximity.com/articles/next-steps-reevaluating-the-value-of-step-3'
	},

	dox_speech_bubbles_v2: {
		description: `An Apology Is Not a Career Ender`,
		pic: 'dox/speech_bubbles_v2.webp',
		link: 'https://www.doximity.com/articles/99146d36-4e25-486b-bb50-92bd6fab87bd'
	},

	dox_stepping_stones: {
		description: `Medical Student and ... ?`,
		pic: 'dox/stepping_stones.webp',
		link: 'https://opmed.doximity.com/articles/medical-student-and'
	},

	dox_safety_pins: {
		description: `Why I Use Safety Pins in the OR`,
		pic: 'dox/safety_pins.gif',
		link: 'https://opmed.doximity.com/articles/why-i-use-safety-pins-in-the-or'
	},

	dox_turn_every_stone: {
		description: `Are We Missing the H in H&P?`,
		pic: 'dox/turn_every_stone.webp',
		link: 'https://opmed.doximity.com/articles/are-we-missing-the-h-in-h-p'
	},

	dox_empathy_waves: {
		description: `The Importance of Community Engagement for Medical Students`,
		pic: 'dox/empathy_waves.webp',
		link: 'https://opmed.doximity.com/articles/the-importance-of-community-engagement-for-medical-students'
	},

	dox_preg_cancer_pt: {
		description: `Why a Neurosurgeon is Now Thinking About Pregnancy and Cancer`,
		pic: 'dox/preg_cancer_pt.webp',
		link: 'https://opmed.doximity.com/articles/why-a-neurosurgeon-is-now-thinking-about-pregnancy-and-cancer'
	},

	dox_polishing_diamond_v2: {
		description: `Are Any of Us Meeting the Standard of the “Ideal” Physician?`,
		pic: 'dox/polishing_diamond_v2.webp',
		link: 'https://www.doximity.com/articles/ea533de9-28c3-4700-a46f-61d6273cc010'
	},

	dox_template_hands_meds: {
		description: `First-in-Class Agent Addresses Unmet Need in Myeloma`,
		pic: 'dox/template_hands_meds.webp',
		link: 'https://opmed.doximity.com/articles/first-in-class-agent-addresses-unmet-need-in-myeloma'
	},

	dox_wim_letter_to_self: {
		description: `A Letter to My Younger Self as a Woman in Medicine`,
		pic: 'dox/wim_letter_to_self.gif',
		link: 'https://opmed.doximity.com/articles/a-letter-to-my-younger-self-as-a-woman-in-medicine'
	},

	dox_age_descrimination: {
		description: `Is It Ever All Right to Discriminate Based on Age?`,
		pic: 'dox/age_descrimination.webp',
		link: 'https://opmed.doximity.com/articles/is-it-ever-all-right-to-discriminate-based-on-age'
	},

	dox_parental_leave: {
		description: `Why is Health Care So Behind When It Comes to Parental Leave?`,
		pic: 'dox/parental_leave.webp',
		link: 'https://opmed.doximity.com/articles/why-is-health-care-so-behind-when-it-comes-to-parental-leave'
	},

	dox_handshake_series3: {
		description: `Return of the in-Person Conference: A Guide to Networking`,
		pic: 'dox/handshake_series3.webp',
		link: 'https://www.doximity.com/articles/9773ffe1-f24d-42da-b793-75f614d1f360'
	},


	dox_venn_diagram_v2: {
		description: `What We Can Learn from the Caregivers of Our Patients`,
		pic: 'dox/venn_diagram_v2.webp',
		link: 'https://www.doximity.com/articles/0a9e935d-dc0e-42ea-85aa-50da49e039c3'
	},

	dox_micro_vs_macro: {
		description: `Designing Your Medical Career: An Evolving Definition of Impact`,
		pic: 'dox/micro_vs_macro.webp',
		link: 'https://opmed.doximity.com/articles/designing-your-medical-career-an-evolving-definition-of-impact'
	},

	dox_medical_van: {
		description: `We Can’t Pick and Choose Harm Reduction`,
		pic: 'dox/medical_van.webp',
		link: 'https://opmed.doximity.com/articles/we-can-t-pick-and-choose-harm-reduction'
	},

	dox_leadership_boat: {
		description: `A Resident's Simple Question Made All the Difference`,
		pic: 'dox/leadership_boat.webp',
		link: 'https://opmed.doximity.com/articles/a-resident-s-simple-question-made-all-the-difference'
	},

	dox_extreme_exercise: {
		description: `I Thought Intense Exercise Was Helping Me, Not Hurting Me`,
		pic: 'dox/extreme_exercise.gif',
		link: 'https://opmed.doximity.com/articles/i-thought-intense-exercise-was-helping-me-not-hurting-me'
	},

	dox_crystal_ball: {
		description: `The Six Senses of a Surgeon`,
		pic: 'dox/crystal_ball.webp',
		link: 'https://opmed.doximity.com/articles/the-six-senses-of-a-surgeon'
	},

	dox_hockey: {
		description: `Reflecting on My Childhood Hockey Career As a Neurosurgery Resident`,
		pic: 'dox/hockey.webp',
		link: 'https://opmed.doximity.com/articles/reflecting-on-my-childhood-hockey-career-as-a-neurosurgery-resident'
	},

	dox_empathy_brain: {
		description: `Simulation Training Just Doesn’t Check The “Empathy Box”`,
		pic: 'dox/empathy_brain.webp',
		link: 'https://opmed.doximity.com/articles/simulation-training-just-doesn-t-check-the-empathy-box'
	},

	dox_8_ball: {
		description: `Your Medical Management Should Be Better Than a Magic 8 Ball`,
		pic: 'dox/8_ball.gif',
		link: 'https://opmed.doximity.com/articles/your-medical-management-should-be-better-than-a-magic-8-ball'
	},

	dox_airplanePatient: {
		description: `We Aren’t Taught Enough to Be Doctors Outside of the Hospital`,
		pic: 'dox/airplanePatient.webp',
		link: 'https://opmed.doximity.com/articles/we-aren-t-taught-enough-to-be-doctors-outside-of-the-hospital'
	},

	dox_relay_baton: {
		description: `It Takes a Village to Heal a Patient`,
		pic: 'dox/relay_baton.webp',
		link: 'https://opmed.doximity.com/articles/it-takes-a-village-to-heal-a-patient'
	},

	dox_black_girl_magic: {
		description: `Instagram vs. Reality: A Critical Look at Black Teens' Mental Health`,
		pic: 'dox/black_girl_magic.webp',
		link: 'https://opmed.doximity.com/articles/instagram-vs-reality-a-critical-look-at-black-teens-mental-health'
	},

	dox_green_door: {
		description: `How Resilience is Like Pornography`,
		pic: 'dox/green_door.webp',
		link: 'https://opmed.doximity.com/articles/how-resilience-is-like-pornography'
	},

	dox_medical_retraumatization: {
		description: `Healing for Trauma Survivors Can Start With You`,
		pic: 'dox/medical_retraumatization.webp',
		link: 'https://opmed.doximity.com/articles/healing-for-trauma-survivors-can-start-with-you?_csrf_attempted=yes'
	},

	dox_vaccine_syringe: {
		description: `Incidence or Coincidence?`,
		pic: 'dox/vaccine_syringe.gif',
		link: 'https://opmed.doximity.com/articles/incidence-or-coincidence'
	},

	dox_handshake_series1: {
		description: `How to Keep Your Bias Where It Belongs: Out of the Exam Room`,
		pic: 'dox/handshake_series1.gif',
		link: 'https://opmed.doximity.com/articles/how-to-keep-your-bias-where-it-belongs-out-of-the-exam-room'
	},

	dox_suture_tails: {
		description: `The Suture Tail Matters, But So Does Everything Else`,
		pic: 'dox/suture_tails.gif',
		link: 'https://opmed.doximity.com/articles/the-suture-tail-matters-but-so-does-everything-else'
	},

	dox_flyers: {
		description: `Practice Changer: The Alliance PROSPECT Trial Finds that Some Patients with Intermediate Risk, Localized Rectal Cancers Can Avoid Radiation without Compromising Their Odds of Cure`,
		pic: 'dox/flyers.webp',
		link: 'https://opmed.doximity.com/articles/practice-changer-the-alliance-prospect-trial-finds-that-some-patients-with-intermediate-risk-localized-rectal-cancers-can-avoid-radiation-without-compromising-their-odds-of-cure'
	},

	dox_coffee_mugs: {
		description: `How a Plain Coffee Mug May Make Me a Better Doctor`,
		pic: 'dox/coffee_mugs.gif',
		link: 'https://opmed.doximity.com/articles/how-a-plain-coffee-mug-may-make-me-a-better-doctor'
	},

	dox_socialDistancing: {
		description: `COVID-19: Is Mask-Wearing Really Unnecessary? A Cultural Judgement Call`,
		pic: 'dox/socialDistancing.gif',
		link: 'https://www.doximity.com/articles/fd6eee4f-66d8-46f5-8dd3-4ee491e20af4'
	},

	dox_silhouette_awards: {
		description: `The Op-Med Awards: In Celebration of Medicine`,
		pic: 'dox/silhouette_awards.webp',
		link: 'https://opmed.doximity.com/articles/the-op-med-awards-in-celebration-of-medicine'
	},

	dox_standardized_patient: {
		description: `We Need Standardized Patients. Why Not Med Students?`,
		pic: 'dox/standardized_patient.gif',
		link: 'https://opmed.doximity.com/articles/we-need-standardized-patients-why-not-med-students'
	},

	dox_waiting_room: {
		description: `On Duty in the Surgery Waiting Room`,
		pic: 'dox/waiting_room.webp',
		link: 'https://opmed.doximity.com/articles/on-duty-in-the-surgery-waiting-room?_csrf_attempted=yes'
	},

	dox_poetry: {
		description: `What Clinicians Can Learn From Poetry`,
		pic: 'dox/poetry.webp',
		link: 'https://opmed.doximity.com/articles/what-clinicians-can-learn-from-poetry?_csrf_attempted=yes'
	},

	dox_mindset_shift_v2: {
		description: `Medicine Should Be a Meaningful Job. How Do We Fix It?`,
		pic: 'dox/mindset_shift_v2.webp',
		link: 'https://www.doximity.com/articles/05560e04-4177-4421-b5a3-fc507adfb91a'
	},

	dox_covidTarot: {
		description: `How Giving Vaccinations Rekindled My Love of Practicing Medicine`,
		pic: 'dox/covidTarot.gif',
		link: 'https://opmed.doximity.com/articles/how-giving-vaccinations-rekindled-my-love-of-practicing-medicine?_csrf_attempted=yes'
	},

	dox_improving_residency: {
		description: `Improving Residency Because We Love It, Not Hate It`,
		pic: 'dox/improving_residency.webp',
		link: 'https://opmed.doximity.com/articles/improving-residency-because-we-love-it-not-hate-it?_csrf_attempted=yes'
	},

	dox_personality_tests: {
		description: `Can Personality Tests Make You a Better Doctor?`,
		pic: 'dox/personality_tests.webp',
		link: 'https://opmed.doximity.com/articles/can-personality-tests-make-you-a-better-doctor?_csrf_attempted=yes'
	},

	dox_racist_algo: {
		description: `Taking Race(ism) Out of the Equation`,
		pic: 'dox/racist_algo.webp',
		link: 'https://opmed.doximity.com/articles/taking-race-ism-out-of-the-equation?_csrf_attempted=yes'
	},

	dox_video_games: {
		description: `Should Doctors Be Playing More Video Games?`,
		pic: 'dox/video_games.gif',
		link: 'https://opmed.doximity.com/articles/should-doctors-be-playing-more-video-games?_csrf_attempted=yes'
	},

	// __ID__: {
	// 	description: `__DESCRIPTION__`,
	// 	pic: 'dox/__ID__.webp',
	// 	link: '__LINK__'
	// },

}
